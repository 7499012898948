import { Dependencies } from 'constitute';
import Container from '@/Framework/DI/Container';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import DataroomRpcDispatcher from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import DrsRpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import Rpc, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { ICreateSessionPayload } from './interfaces';

@Dependencies(Container)
class SessionRepository {
  private dataroomHttpDispatcher: (tenant: string) => Rpc;

  private drsRpcDispatcher: Rpc;

  constructor(container) {
    container.schedulePostConstructor(
      (drsRpcDispatcher, dataroomHttpDispatcher) => {
        this.dataroomHttpDispatcher = dataroomHttpDispatcher;
        this.drsRpcDispatcher = drsRpcDispatcher;
      }, [DrsRpcDispatcher, DataroomRpcDispatcher]);
  }

  /**
   * Create session investor DRS
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  createInvestorSessionDrs = async (data) => {
    let req = new Request('investor.auth', data);
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * Create session investor Evercall
   *
   * @param {Object} data
   * @param {String} token
   * @return {Promise.<*>}
   */
  createInvestorSessionEvercall = async (data, token) => {
    let req = new Request('evercall.dashboard.auth', data, { token });
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * Create session investor Evercall OACC Registration
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  createInvestorSessionEvercallOacc = async (data) => {
    let req = new Request('evercall.oacc.auth', data);
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /** Create session for dataroom */
  async createDataroomSession(data: ICreateSessionPayload, tenant: DataroomTenant): Promise<string> {
    let req = new Request('user.authenticate', data);
    let response = await this.dataroomHttpDispatcher(tenant).call<RpcSuccess>(req);

    // @ts-ignore
    return response.getResult().ssid;
  }

  /**
   * Verify that current session is valid for DealVDR
   *
   * @return {Promise<*>}
   */
  async verifyDataroomSession(tenant: DataroomTenant) {
    let req = new Request('user.verify_session');
    let response = await this.dataroomHttpDispatcher(tenant).call<RpcSuccess>(req);

    return response.getResult();
  }
}

export default SessionRepository;
